module.exports = {
    name: 'Strive by KK Fit',
    logo: 'https://img.genflow.com/kk-strive/checkout/logo.png',
    vendorUUID: '015fb9a3-08dc-4f60-b648-a8836aa572ad',
    metaData: {
        title: 'Strive by KK Fit',
        description: 'Strive to reach your full potential.',
        author: 'GENFLOW',
        url: 'https://www.kkfit.com/',
        image: 'https://img.genflow.com/kk-strive/checkout/logo.png',
    },
    theme: {
        colors: { // Note these need to be copied into $theme-colors in src/styles/global.scss
            primary: '#FFA8A0',
            text: '#697386',
            ctaText: '#fff',
        },
    },
    analytics: {
        gtag: '',
        facebookPixel: '',
        tagManager: '',
        tapfiliate: '',
        // segment: '', - Stored in env
    },
    links: {
        terms: 'https://kkfit.com/terms-conditions',
        privacy: 'https://kkfit.com/privacy-policy',
        support: 'https://kkfit.zendesk.com/hc/en-gb',
        genflow: 'https://genflow.com',
        ios: 'https://apps.apple.com/us/app/appname/id6444265629',
        android: 'https://play.google.com/store/apps/details?id=com.kkfit.strive'
    },
    forms: {
        errors: {
            existingUser: 'This user already exists, please login.',
            invalidPassword: 'The password needs to have 6 or more characters.',
            unknownError: 'The registration could not be processed.',
            userNotFound: 'Incorrect email/password combination',
        },
    },
    pages: {
        register: {
            title: 'Sign Up to',
            ctaText: 'Register',
            ctaLoadingText: 'Registering',
        },
        login: {
            title: 'Login to',
            ctaText: 'Login',
            ctaLoadingText: 'Logging In',

        },
    },
    products: [
        {
            title: '1 Month',
            product: '1month',
            // coupon: '9FYJJHD7',
            // label: '20% OFF',
        }, {
            title: '6 Months',
            product: '6month',
            // coupon: 'ms1Q91ab',
            // label: '20% OFF',
        }, {
            title: '12 Months',
            product: '12month',
            // coupon: '802jZxdq',
            // label: '20% OFF',
        },
    ],
    saleProducts: [
    ],
    // currencies: {
    //     available: [
    //         {
    //             code: 'GBP',
    //             icon: '🇬🇧',
    //         },
    //         {
    //             code: 'SEK',
    //             icon: '🇸🇪',
    //         },
    //         {
    //             code: 'EUR',
    //             icon: '🇪🇺',
    //         },
    //         {
    //             code: 'USD',
    //             icon: '🇺🇸',
    //         },
    //     ],
    //     default: 'EUR',
    // },
};
