module.exports = {
    name: 'Strength by Mari',
    logo: 'https://img.genflow.com/slay/checkout/logo2022.png',
    vendorUUID: 'f514e99d-5e52-46f2-a021-315eb06d7109',
    metaData: {
        title: 'Strength by Mari',
        description: 'Join the Strength by Mari App by Mari Llewellyn',
        author: 'GENFLOW',
        url: 'https://slayapp.com/',
        image: 'https://img.genflow.com/slay/checkout/logo2022.png',

    },
    theme: {
        colors: {
            primary: '#FF8F4B',
            text: '#342926',
            ctaText: '#fff',
        },
    },
    analytics: {
        gtag: '',
        facebookPixel: '',
        tagManager: '',
        tapfiliate: '38621-88366e',
        // segment: '', - Stored in env
    },
    links: {
        terms: 'https://strengthbymari.com/pages/terms-and-condition',
        privacy: 'https://strengthbymari.com/policies/privacy-policy',
        support: 'https://strengthbymari.zendesk.com/hc/en-gb',
        genflow: 'https://genflow.com',
        ios: 'https://apps.apple.com/us/app/slay-by-mari/id1524686854',
        android: 'https://play.google.com/store/apps/details?id=com.bloomnu.slay&hl=en_GB&gl=US',
    },
    forms: {
        errors: {
            existingUser: 'This user already exists, please login.',
            invalidPassword: 'The password needs to have 6 or more characters.',
            unknownError: 'The registration could not be processed.',
            userNotFound: 'Incorrect email/password combination',
        },
    },
    pages: {
        register: {
            title: 'Sign Up to',
            ctaText: 'Register',
            ctaLoadingText: 'Registering',
        },
        login: {
            title: 'Login to',
            ctaText: 'Login',
            ctaLoadingText: 'Logging In',

        },
    },
    products: [
        {
            title: '1 Month',
            product: '1monthsale',
            // coupon: 'ZhGGIP28',
            // label: '10% OFF',
        }, {
            title: '6 Months',
            product: '6monthsale',
            // coupon: 'BPaEOGoP',
            // label: '20% OFF',
        }, {
            title: '12 Months',
            product: '12monthsale',
            // coupon: 'LISInUUN',
            // label: '30% OFF',
        },
    ],
    saleProducts: [
        {
            title: '1 Month',
            product: '1monthsale',
            label: null,
        }, {
            title: '6 Months',
            product: '6monthsale',
            label: null,
        }, {
            title: '12 Months',
            product: '12monthsale',
            label: null,
        },
    ],
    // referralProducts: [
    //     {
    //         title: '1 Month',
    //         product: '1monthsale',
    //         label: 'Referrals offer applied',
    //         coupon: 'eukh1Isr',
    //     }, {
    //         title: '6 Months',
    //         product: '6monthsale',
    //         label: 'Referrals offer applied',
    //         coupon: '89oEDBja',
    //     }, {
    //         title: '12 Months',
    //         product: '12monthsale',
    //         label: 'Referrals offer applied',
    //         coupon: '4Xg14aq6',
    //     },
    // ],
};
