module.exports = {
    name: 'THRST',
    logo: 'https://img.genflow.com/thrst/checkout/logo.png',
    vendorUUID: '29cede06-952e-4d7e-9184-9f18490b9a4e',
    metaData: {
        title: 'THRST',
        description: '10 years of training experience, all in one powerful app.',
        author: 'GENFLOW',
        url: 'https://www.thrstofficial.com/',
        image: 'https://img.genflow.com/thrst/checkout/logo.png',
    },
    theme: {
        colors: {
            primary: '#FFCF40',
            text: '#697386',
            ctaText: '#000000',
        },
    },
    analytics: {
        gtag: '',
        facebookPixel: '',
        tagManager: '',
        tapfiliate: '43075-ee0d85',
        // segment: '', - Stored in env
    },
    links: {
        terms: 'https://thrstofficial.com/pages/terms-and-conditions',
        privacy: 'https://thrstofficial.com/pages/privacy-policy',
        support: 'https://support.thrstofficial.com/hc/en-us',
        genflow: 'https://genflow.com',
        ios: 'https://apps.apple.com/gb/app/thrst/id1547079263',
        android: 'https://play.google.com/store/apps/details?id=com.thrst.app',
    },
    forms: {
        errors: {
            existingUser: 'This user already exists, please login.',
            invalidPassword: 'The password needs to have 6 or more characters.',
            unknownError: 'The registration could not be processed.',
            userNotFound: 'Incorrect email/password combination',
        },
    },
    pages: {
        register: {
            title: 'Sign Up to',
            ctaText: 'Register',
            ctaLoadingText: 'Registering',
        },
        login: {
            title: 'Login to',
            ctaText: 'Login',
            ctaLoadingText: 'Logging In',

        },
    },
    products: [
        {
            title: '1 Month',
            product: '1month',
            // label: '10% off',
            // coupon: 'B46EfdIS',
        }, {
            title: '3 Months',
            product: '3month',
            // label: '20% off',
            // coupon: 'oWpG1bS6',
        }, {
            title: '12 Months',
            product: '12month',
            // label: '30% off',
            // coupon: 'mHh21nAn',
        },
    ],
};
